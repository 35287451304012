<script setup>
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { ref, onMounted, computed } from "vue";
import c3api from "../../c3api";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import useClients from "@/hooks/useClients";
import useProducts from "@/hooks/useProducts";
import { onBeforeMount } from "vue";
import debounce from "lodash/debounce";
import Pluralize from "pluralize";

const pluralize = Pluralize;

const toast = useToast();
const product = ref({
  client_id: null,
  customer_sku: "",
  name: "",
  weight: null,
  cubic_feet: null,
  length: null,
  width: null,
  height: null,
  liters: "",
  scc: "",
  plcb_code: "",
  vintage: "",
  units: null,
  units1: null,
  units_per_units1: 12,
  units1_per_units2: 0,
  units2: "pallet",
  use_containers: false,
  upc: null,
  is_alcohol: null,
  lowest_selling_unit: null,
});

const router = useRouter();
const { clients, fetchClients } = useClients();
const { fetchUoms, uoms } = useProducts();

const props = defineProps({
  product: Object,
});

const isUpdate = ref(false);

onBeforeMount(async () => {

  if (props.product) {
    isUpdate.value = true;
    Object.assign(product.value, props.product);
  }

  await fetchClients(clientUrlParams);
  await fetchUoms();
});

onMounted(() => {
  if (isUpdate.value) {
    setPageTitle("Products / Update Product");
  } else {
    setPageTitle("Products / New Product");
  }
});

const isAlcohol = [
  { title: "Yes", value: true },
  { title: "No", value: false },
];

const clientUrlParams = {
  filter: {
    name: {
      type: "contains",
      filter: "",
    },
  },
  sort: [
    {
      sort: "asc",
      colId: "name",
    },
  ],
  limit: 500,
};

const debouncedFetchClients = debounce(async (query) => {
  clientUrlParams.filter.name.filter = query;
  await fetchClients(clientUrlParams);
}, 300);

const validateProduct = (product) => {

  if (!product.value.client_id) {
    toast.error("Please select a client.");
    throw new Error('Please select a client.');
  }

  if (product.value.units1 == "plt") {
    // if plt is selected for pack UOM then dont sent containers unit (units1perunits2)
    product.value.units1_per_units2 = null;
    product.value.use_containers = false;
  }
};

const updateProduct = async (product) => {
  const response = await c3api.patch(`/products/${product.value.id}`, product.value);
  toast.success("Product Updated Successfully");
}

const saveProduct = async (product) => {
  const response = await c3api.post("/products", product.value);
  toast.success("Product Added Successfully");
}

const save = async () => {

  try {
    validateProduct(product);
    if (isUpdate.value) {
      updateProduct(product);
    } else {
      saveProduct(product);
    }
    router.push("/products");

  } catch (err) {
    console.error(err);
    toast.error(err.response.data.data.join(". "));
  }
};

const lowestSellingUnitValues = computed(() => {
  const units = product.value.units;
  const unit1 = product.value.units1;
  let arr = [];

  if (units) {
    arr.push(units);
  }
  if (unit1) {
    arr.push(unit1);
    if (unit1 !== "plt") {
      arr.push("plt");
    }
  }

  return arr;
});

</script>

<template>
  <v-card-title>{{ pageTitle }}</v-card-title>

  <v-card flat class="mx-4 mt-4">
    <v-card-text>
      <v-row align="center">
        <v-col cols="2">
          <label for="client">Client <span class="text-red">*</span></label>
        </v-col>
        <v-col cols="10">
          <v-autocomplete v-model="product.client_id" :items="clients"
            @update:search="(query) => debouncedFetchClients(query)" item-title="name" item-value="id" id="client"
            hide-details="auto" placeholder="Select a client..." name="client"></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <label for="SKU">SKU <span class="text-red">*</span></label>
        </v-col>
        <v-col cols="10">
          <v-text-field v-model="product.customer_sku" id="SKU" hide-details="auto" placeholder="SKU"
            name="SKU"></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="Description">Description <span class="text-red">*</span></label>
        </v-col>
        <v-col cols="10">
          <v-text-field v-model="product.name" id="Description" hide-details="auto" placeholder="Description"
            name="Description"></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="Weight">Weight (lbs.)</label>
        </v-col>
        <v-col cols="10">
          <v-text-field v-model="product.weight" id="Weight" hide-details="auto" placeholder="Weight (lbs.)"
            name="Weight" type="number"></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="Cubic">Cubic (ft2)</label>
        </v-col>

        <v-col cols="10">
          <v-text-field v-model="product.cubic_feet" id="Cubic" hide-details="auto" placeholder="Cubic (ft2)"
            name="Cubic" type="number"></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="upc">UPC</label>
        </v-col>

        <v-col cols="10">
          <v-text-field v-model="product.upc" id="upc" placeholder="UPC" name="upc"></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="2">
          <label>LWH (in.)</label>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="product.length" name="LWH" placeholder="Length" density="compact" variant="outlined"
            hide-details="auto" type="number" step="0.01"></v-text-field>
        </v-col>
        X
        <v-col cols="3">
          <v-text-field v-model="product.width" name="LWH" placeholder="Width" density="compact" variant="outlined"
            hide-details="auto" type="number" step="0.01"></v-text-field>
        </v-col>
        X
        <v-col cols="3">
          <v-text-field v-model="product.height" name="LWH" placeholder="Height" density="compact" variant="outlined"
            hide-details="auto" type="number" step="0.01"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="text-end" justify="end" align="center">
        <v-col cols="4" sm="2" md="1">
          <label for="Liters">Liters</label>
        </v-col>
        <v-col cols="8" sm="4" md="2">
          <v-text-field v-model="product.liters" id="Liters" name="Liters" density="compact" variant="outlined" hide-details="auto"
            type="number"></v-text-field>
        </v-col>

        <v-col cols="4" sm="2" md="1">
          <label for="SCC">SCC</label>
        </v-col>
        <v-col cols="8" sm="4" md="2">
          <v-text-field v-model="product.scc" id="SCC" name="SCC" density="compact" variant="outlined"
            hide-details="auto"></v-text-field>
        </v-col>

        <v-col cols="4" sm="2" md="1">
          <label for="PLCB Code">PLCB Code</label>
        </v-col>
        <v-col cols="8" sm="4" md="2">
          <v-text-field v-model="product.plcb_code" id="PLCB Code" name="PLCB Code" density="compact" variant="outlined"
            hide-details="auto"></v-text-field>
        </v-col>

        <v-col cols="4" sm="2" md="1">
          <label for="Vintage">Vintage</label>
        </v-col>
        <v-col cols="8" sm="4" md="2">
          <v-text-field v-model="product.vintage" id="Vintage" name="Vintage" density="compact" variant="outlined"
            hide-details="auto" type="number"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <div class="mx-4 mt-8">
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title class="bg-surface-light">Each UOM <span class="text-red">*</span></v-card-title>
          <v-card-text class="mt-5">
            <v-select placeholder="Select UOM..." :disabled="isUpdate" density="compact" variant="outlined" hide-details="auto"
              :items="uoms.each_units?.valid_values" v-model="product.units"></v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title class="bg-surface-light">Pack UOM <span class="text-red">*</span></v-card-title>
          <v-card-text class="mt-5">
            <v-select placeholder="Select UOM..." :disabled="isUpdate" density="compact" variant="outlined" hide-details="auto"
              :items="uoms.pack_units?.valid_values" v-model="product.units1"></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>

  <div class="mx-4 mt-8">
    <v-row>
      <v-col cols="12" :sm="product.units1 !== 'plt' ? '6' : '12'">
        <v-card>
          <v-card-title class="bg-surface-light">Pack Units <span class="text-red">*</span></v-card-title>
          <v-card-text class="mt-5">
            <v-text-field v-if="product.units1" :disabled="isUpdate" prefix="There are" density="compact" variant="outlined"
              hide-details="auto" v-model="product.units_per_units1" :suffix="`${product.units ? pluralize(product.units, product.units_per_units1) : 'SELECT EACH UOM'} per ${product.units1
    }`">
            </v-text-field>
            <span v-if="!product.units1">
              <strong style="color: #dc3545">PLEASE SELECT PACK UOM</strong>
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" v-if="product.units1 !== 'plt'">
        <v-card>
          <v-card-title class="bg-surface-light d-flex justify-space-between align-center">
            <span>Container Units</span>
            <v-checkbox :disabled="isUpdate" v-model="product.use_containers" label="Has Container Units?" hide-details="auto"
              density="compact" style="height: 32px; line-height: 20px"></v-checkbox>
          </v-card-title>
          <v-card-text class="mt-5" v-if="product.use_containers">
            <v-text-field :disabled="isUpdate" prefix="There are" density="compact" variant="outlined" placeholder="10" hide-details="auto"
              v-model="product.units1_per_units2" :suffix="`${product.units1
    ? pluralize(product.units1, product.units1_per_units2) +
    ' per ' +
    'plt'
    : 'Select a pack UOM first'
    }`">
            </v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>

  <div class="mx-4 mt-8">
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title class="bg-surface-light">Lowest Selling Unit <span class="text-red">*</span></v-card-title>
          <v-card-text class="mt-5">
            <p class="w-100 mb-2">
              Select the unit of measure type that is the lowest selling unit for this
              product.
            </p>
            <v-select :disabled="isUpdate" placeholder="Select UOM..." density="compact" variant="outlined" hide-details="auto"
              :items="lowestSellingUnitValues" v-model="product.lowest_selling_unit"></v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title class="bg-surface-light">Product Is Alcohol <span class="text-red">*</span></v-card-title>
          <v-card-text class="mt-5">
            <v-select placeholder="Is this product alcoholic?" density="compact" variant="outlined" hide-details="auto"
              :items="isAlcohol" item-text="title" item-value="value" v-model="product.is_alcohol"></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>

  <v-card-actions class="d-flex justify-end mx-2 mt-4">
    <v-btn size="large" variant="flat" color="primary" @click="save"> Save </v-btn>
  </v-card-actions>
</template>
